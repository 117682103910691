<template>
    <div class="text-comparison">
        <div v-for="(item, index) in content" :key="index" class="text-comparison-item" :class="autofocusIndex === index ? 'active' : ''">
            <div class="example">
                <span :key="eIndex" v-for="(e, eIndex) in item.content" :class="e.state === 1 ? 'right' : e.state === 2 ? 'error' : ''">{{
                    e.text
                }}</span>
            </div>
            <div class="write">
                <el-input
                    :ref="'saveTagInput' + index"
                    :disabled="disabled || index != maxActive"
                    @copy.native="notAllow"
                    @cut.native="notAllow"
                    @paste.native="notAllow"
                    @keydown.native="handleKeydown($event, item.inputValue)"
                    class="textInput"
                    @input="handelInput($event, item, index)"
                    :debounce="1000"
                    v-model="item.inputValue"
                    @blur.native="handelBlur"
                    placeholder=""
                ></el-input>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * content=[
 *    {content: [{text: string, state: null | 0 | 1 | 2}, {}, ...],  inputValue } ,
 *    {content: [{text: string, state: null | 0 | 1 | 2}, {}, ...],  inputValue } ,
 * ]
 * eg. 每行60字，每个字一个对象{text: x, state: 0}, text 为练习内容的一个字，state取值 0:未输入 1:输入正确 2:输入错误
 *     inputValue为用户针对一行的输入 字符串，
 *    defaultInput为用户曾经的输入，在查看练习答案时用到。
 * content = [
 *   [
 *     // 第一行
 *    {
 *      content: [{text: "被", state: 0}, {text: "告", state: 0}, {text: "人", state: 0}, ...] // 60
 *      inputValue: ""
 *    },
 *    // 第二行
 *    {
 *      content: [{text: "3", state: 0}, {text: "1", state: 0}, {text: "日", state: 0}, ...] // 60
 *      inputValue: ""
 *    },
 *    ...
 *    (字数/ 60) 行
 *   ],
 * ]
 */
export default {
    props: ['data', 'disabled', 'defaultInput'],
    data() {
        return {
            content: [],
            maxActive: 0, // 当前输入完成行数
            rightNum: 0,
            wrongNum: 0,
            autofocusIndex: 0, // 输入焦点行下标，从0开始，只能递增。输入时自动切换，不能手动切换。输入过的行自动 disabled
            wordsPerLine: 60, // 每行字数
        }
    },
    mounted() {
        this.content = []
        // 根据输入 data 和 defaultInput 初始化 content
        // data为字符串数组["111","222","222",... ],每行一个字符串，每行默认60字
        this.data.forEach((item, index) => {
            const arr = []
            let inputValue = ''
            // 如果 defaultInput 存在(查看答案)
            this.defaultInput && this.defaultInput[index] && (inputValue = this.defaultInput[index])
            // 一行拆分出60个对象，每个字一个对象
            item.split('').forEach((e) => {
                arr.push({ text: e, state: null })
            })
            // 取第一行的字数，作为每行字数
            if (index === 0) {
                this.wordsPerLine = arr.length
            }

            // content 添加一行
            this.content.push({ content: arr, inputValue })
            const inputWordsArr = inputValue.split('')
            arr.forEach((e, i) => {
                if (e.text === inputWordsArr[i]) {
                    // 输入和参照一致，输入正确
                    arr[i].state = 1
                } else if (inputWordsArr[i]) {
                    // 输入和参照不一致，输入错误
                    arr[i].state = 2
                } else {
                    // 未输入
                    arr[i].state = 0
                }
            })
        })
    },
    methods: {
        // 统计 正确数、错字数
        getRightWrongNum() {
            let rightNum = 0
            let wrongNum = 0
            // inputContent为输入字符串数组，每行一个字符串
            const inputContent = []
            this.content.forEach((item) => {
                item.inputValue && item.inputValue.length > 0 && inputContent.push(item.inputValue)
                item.content.map((e) => {
                    if (e.state === 1) {
                        rightNum += 1
                    } else if (e.state === 2) {
                        wrongNum += 1
                    }
                })
            })
            return { wrongNum, rightNum, inputContent }
        },
        maxActiveInput(e) {
            if (e === 'add') {
                this.maxActive += 1
            } else if (e === 'reduce') {
                this.maxActive -= 1
            }
        },
        // 每次输入框变化，会触发此函数
        // item 输入框当前字符串，row：content中的一行，rowIndex：当前行下标
        // row =     {
        //    content: [{text: "同", state: 0}, {text: "志", state: 0}, {text: "们", state: 0}, ...] // 60
        //    inputValue: "同志们"
        // }
        handelInput(item, row, rowIndex) {
            console.log('item', item)
            console.log('row', row)
            console.log('rowIndex', rowIndex)
            if (item.length >= row.content.length) {
                // console.log('到尾巴了', rowIndex)
                // 忘记是干啥的了
                // !!item.substring(0, row.content.length) && (item = item.substring(0, row.content.length))
                // 下一行自动获取焦点
                this.autofocusIndex = rowIndex + 1
                const refName = 'saveTagInput' + this.autofocusIndex
                const that = this
                this.$nextTick(() => {
                    that.$refs[refName][0].focus()
                })
                // 溢出文字自动填入下一行
                const text = item.substring(row.content.length)
                this.content[this.autofocusIndex].inputValue = text
                // 计算可输入的最大行数
                this.maxActiveInput('add')
            }
            if (0 === item.length && rowIndex !== 0) {
                // console.log('到顶了', rowIndex)
                // 上一行自动获取焦点
                this.autofocusIndex = rowIndex - 1
                const refName = 'saveTagInput' + this.autofocusIndex
                const that = this
                this.$nextTick(() => {
                    that.$refs[refName][0].focus()
                })
                // 计算可输入的最大行数
                this.maxActiveInput('reduce')
            }
            row.content.forEach((e, i) => {
                item.split('')[i]
                if (e.text === item.split('')[i]) {
                    row.content[i].state = 1
                } else if (item.split('')[i]) {
                    row.content[i].state = 2
                } else {
                    row.content[i].state = 0
                }
            })
            // 上报当前行完成字数, 输入只能从上往下，总字数 = 当前行字数 + 历史行总字数
            const filteredData = row.content.filter((item) => item.state === 1 || item.state === 2)
            const count = filteredData.length
            this.$emit('totalCountChange', count + rowIndex * this.wordsPerLine)
        },
        handleKeydown(e, inputValue) {
            if (e.key === 'Backspace' && inputValue) {
                this.$emit('handleKeydown')
            }
        },
        notAllow(e) {
            e.preventDefault()
        },
    },
    // watch: {
    //     input_content(nValue) {
    //         console.log(nValue, 'nValue')
    //     },
    // },
}
</script>

<style scoped lang="scss">
.text-comparison-item {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 15px 0 10px 30px;
    background: #fff;
    border: 1px solid #ececec;
    border-radius: 6px;
    &.active {
        box-shadow: 0 3px 29px 0 rgba(220, 220, 220, 0.95);
    }
    .example {
        text-align: left;
        margin-bottom: 10px;
        .error {
            color: red;
        }
        .right {
            color: green;
        }
    }
    .write::v-deep {
        input {
            border: none;
        }
        .el-input__inner {
            padding: 0 !important;
            font-size: 16px;
        }
        .el-input.is-disabled .el-input__inner {
            background: #fff !important;
        }
    }
}
</style>
